import React, { useState } from "react";

import { Table as NeetoUITable } from "neetoui";

import { CERTIFICATES_TABLE_COLUMN_DATA } from "./constants";
import EditCertificatePane from "./Pane/Edit";

const Table = ({
  selectedCertificateIds,
  setSelectedCertificateIds,
  certificates = [],
  fetchCertificates,
  currentPageNumber,
  handlePageChange,
  rowData,
  totalCount,
}) => {
  const [showEditCertificate, setShowEditCertificate] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState({});

  return (
    <>
      <div className="certificates-table-height w-full">
        <NeetoUITable
          {...{ currentPageNumber, handlePageChange, rowData, totalCount }}
          allowRowClick
          fixedHeight
          rowSelection
          columnData={CERTIFICATES_TABLE_COLUMN_DATA}
          defaultPageSize={100}
          rowData={certificates}
          selectedRowKeys={selectedCertificateIds}
          onRowClick={(_, certificate) => {
            setSelectedCertificate(certificate);
            setShowEditCertificate(true);
          }}
          onRowSelect={selectedRowKeys =>
            setSelectedCertificateIds(selectedRowKeys)
          }
        />
      </div>
      <EditCertificatePane
        certificate={selectedCertificate}
        fetchCertificates={fetchCertificates}
        setShowPane={setShowEditCertificate}
        showPane={showEditCertificate}
      />
    </>
  );
};

export default Table;
