import React, { useState, useEffect } from "react";

import EmptyList from "images/EmptyList";
import { Delete, Plus } from "neetoicons";
import { Button, PageLoader } from "neetoui";
import { Container, Header, SubHeader } from "neetoui/layouts";

import certificatesApi from "apis/certificates";
import EmptyState from "components/commons/EmptyState";

import DeleteAlert from "./DeleteAlert";
import NewCertificatePane from "./Pane/Create";
import Table from "./Table";

const Certificates = () => {
  const [loading, setLoading] = useState(true);
  const [showNewCertificatePane, setShowNewCertificatePane] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedCertificateIds, setSelectedCertificateIds] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [rowData, setRowData] = useState([]);

  const totalCount = certificates.length;

  useEffect(() => {
    fetchCertificates();
  }, []);

  const fetchCertificates = async () => {
    try {
      setLoading(true);
      const {
        data: { certificates },
      } = await certificatesApi.fetch();
      setCertificates(certificates);
      setRowData(certificates);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  const handlePageChange = (pageNumber, size) => {
    setCurrentPageNumber(pageNumber);
    const nextPageStartingIndex = (pageNumber - 1) * size;
    setRowData(
      certificates.slice(nextPageStartingIndex, nextPageStartingIndex + size)
    );
  };

  return (
    <Container>
      <Header
        title="Certificates"
        actionBlock={
          <Button
            icon={Plus}
            label="Add new Certificate"
            size="small"
            onClick={() => setShowNewCertificatePane(true)}
          />
        }
      />
      {certificates.length ? (
        <>
          <SubHeader
            rightActionBlock={
              <Button
                disabled={!selectedCertificateIds.length}
                icon={Delete}
                label="Delete"
                size="small"
                onClick={() => setShowDeleteAlert(true)}
              />
            }
          />
          <Table
            certificates={rowData}
            currentPageNumber={currentPageNumber}
            fetchCertificates={fetchCertificates}
            handlePageChange={handlePageChange}
            selectedCertificateIds={selectedCertificateIds}
            setSelectedCertificateIds={setSelectedCertificateIds}
            totalCount={totalCount}
          />
        </>
      ) : (
        <EmptyState
          image={EmptyList}
          primaryAction={() => setShowNewCertificatePane(true)}
          primaryActionLabel="Add new SSL Certificate"
          subtitle="Create SSL Certificate"
          title="Looks like you don't have any SSL Certificates!"
        />
      )}
      <NewCertificatePane
        fetchCertificates={fetchCertificates}
        setShowPane={setShowNewCertificatePane}
        showPane={showNewCertificatePane}
      />
      {showDeleteAlert && (
        <DeleteAlert
          refetch={fetchCertificates}
          selectedCertificateIds={selectedCertificateIds}
          setSelectedCertificateIds={setSelectedCertificateIds}
          onClose={() => setShowDeleteAlert(false)}
        />
      )}
    </Container>
  );
};

export default Certificates;
